<template>
  <div
    class="m-3 border-2 rounded-md shadow-lg"
    :class="ordenCompraProp.estado ? ' border-green-300' : 'border-red-300'"
  >
    <button class="w-full mt-3" @click="rotate">
      <div class="flex justify-between mx-2">
        <h4 class="text-xl">
          {{ "# " + formatoNumero(ordenCompraProp.id).padStart(4, "0") }}
        </h4>
        <h4 class="text-xl">
          {{ formatoFecha(ordenCompraProp.fecha_entrega) }}
        </h4>
      </div>
      <div class="flex justify-between mx-2 mt-3">
        <h4 v-if="monedaUsdProp" class="text-xl">
          {{ "Total: " + formatoNumero(ordenCompraProp.total_usd) + " $" }}
        </h4>
        <h4 v-else class="text-xl">
          {{ "Total: " + formatoNumero(ordenCompraProp.total_bs) + " Bs" }}
        </h4>
        <img
          :class="classes"
          class="w-5 h-5"
          src="../assets/iconos/arrow.svg"
          alt="arrow"
        />
      </div>
      <div class="flex justify-center my-3">
        <h4 v-if="ordenCompraProp.estado" class="text-xl text-green-400">
          Pagado
        </h4>
        <h4 v-else class="text-xl text-red-500">No pagado</h4>
      </div>
    </button>
    <div class="border-b-2"></div>
    <transition name="expand">
      <div v-if="showCard" class="">
        <div class="flex justify-end my-4">
          <h4 class="text-xl pl-2 mr-2">Bs</h4>
          <togglebutton
            class=""
            :initialState="monedaUsdCard"
            :checkboxName="'tipoMoneda'"
            @setCheckboxVal="setCheckboxVal"
          />
          <h4 class="text-xl pl-2 mr-2">$</h4>
        </div>
        <div class="scrolling-touch overflow-x-auto my-3 mx-2">
          <table class="table-auto mb-6">
            <thead>
              <tr>
                <th class="px-10">Producto</th>
                <th class="px-10">Cant.</th>
                <th v-if="monedaUsdCard" class="px-10">P.Unit($)</th>
                <th v-else class="px-10">P.Unit(Bs)</th>
                <th v-if="monedaUsdCard" class="px-10">Total($)</th>
                <th v-else class="px-10">Total(Bs)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(producto, k) in ordenCompraProp.lista_productos"
                :key="k"
              >
                <td class="">{{ firstLetterUpperCase(producto.producto) }}</td>
                <td class="text-center">
                  <label for="" class="">{{
                    formatoNumero(producto.cantidad) +
                    " " +
                    firstLetterUpperCase(producto.unidad)
                  }}</label>
                </td>
                <td v-if="monedaUsdCard" class="text-center">
                  {{
                    formatoNumero(
                      parseFloat(producto.total_usd) /
                        parseFloat(producto.cantidad)
                    )
                  }}
                </td>
                <td v-else class="text-center">
                  {{
                    formatoNumero(
                      parseFloat(producto.total_bs) /
                        parseFloat(producto.cantidad)
                    )
                  }}
                </td>
                <td v-if="monedaUsdCard" class="text-center">
                  {{ formatoNumero(producto.total_usd) }}
                </td>
                <td v-else class="text-center">
                  {{ formatoNumero(producto.total_bs) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  formatoNumero,
  firstLetterUpperCase,
  formatoFecha,
} from "@/functions.js";

import togglebutton from "@/components/ToggleButton.vue";

export default {
  props: {
    ordenCompraProp: Object,
    monedaUsdProp: Boolean,
  },
  components: {
    togglebutton,
  },
  data() {
    return {
      showCard: false,
      monedaUsdCard: true,
      classes: "",
    };
  },
  mounted() {
    this.monedaUsdCard = this.monedaUsdProp;
  },
  methods: {
    formatoNumero,
    firstLetterUpperCase,
    formatoFecha,
    setCheckboxVal(event) {
      this.monedaUsdCard = event.value;
    },
    rotate() {
      if (!this.showCard) {
        this.classes = "rotate-arrow-enter";
      } else {
        this.classes = "rotate-arrow-leave-to";
      }
      this.showCard = !this.showCard;
    },
  },
};
</script>

<style scoped>
.rotate-arrow-enter, .rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.expand-enter-active {
  transition: max-height 0.25s ease;
  max-height: 24rem;
}
.expand-leave-active {
  transition: max-height 0.1s ease;
  max-height: 24rem;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
