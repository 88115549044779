<template>
  <div class="w-full p-2 mt-6">
    <div v-if="selectCobrar" class="flex justify-center text-xl mb-6">
      <select
        class="bg-white border-2 border-green-300 rounded-md w-48 text-center"
        name=""
        id=""
        v-model="filtroCobrar"
      >
        <option value="noPagado">Total por cobrar</option>
        <option value="pagado">Total cobrado</option>
      </select>
    </div>
    <div v-if="filtroCobrar === 'noPagado'">
      <div class="flex mb-3">
        <h4 class="text-xl pl-2 mr-2">Total por cobrar:</h4>
      </div>
      <div class="flex mb-3">
        <h4 class="text-xl pl-2 mr-2">$:</h4>
        <h4 class="text-xl pl-2 mr-2">
          {{ formatoNumero(parseFloat(proveedorCXC.totalOrdenesPagarUsd)) }}
        </h4>
      </div>
      <div class="flex mb-6">
        <h4 class="text-xl pl-2 mr-0">Bs:</h4>
        <h4 class="text-xl pl-2 mr-2">
          {{ formatoNumero(parseFloat(proveedorCXC.totalOrdenesPagarBs)) }}
        </h4>
      </div>
    </div>
    <div v-if="filtroCobrar === 'pagado'">
      <div class="flex mb-3">
        <h4 class="text-xl pl-2 mr-2">Total cobrado:</h4>
      </div>
      <div class="flex mb-3">
        <h4 class="text-xl pl-2 mr-2">$:</h4>
        <h4 class="text-xl pl-2 mr-2">
          {{ formatoNumero(parseFloat(proveedorCXC.totalOrdenesPagadoUsd)) }}
        </h4>
      </div>
      <div class="flex mb-6">
        <h4 class="text-xl pl-2 mr-0">Bs:</h4>
        <h4 class="text-xl pl-2 mr-2">
          {{ formatoNumero(parseFloat(proveedorCXC.totalOrdenesPagadoBs)) }}
        </h4>
      </div>
    </div>
    <div class="border-b-2"></div>
    <div class="flex justify-end my-6">
      <h4 class="text-xl pl-2 mr-2">Bs</h4>
      <togglebutton
        class=""
        :initialState="monedaUsd"
        :checkboxName="'tipoMoneda'"
        @setCheckboxVal="setCheckboxVal"
      />
      <h4 class="text-xl pl-2 mr-2">$</h4>
    </div>
    <div v-for="(ordenCompra, k) in proveedorCXC.listaOrdenCompra" :key="k">
      <cxccard
        :ordenCompraProp="ordenCompra"
        :monedaUsdProp="monedaUsd"
        :key="componentKey"
      />
    </div>
    <div v-if="!continuaData" class="my-10"></div>
    <div v-if="continuaData" class="flex justify-center w-full my-10">
      <button
        class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
        @click="cargarMasResultados"
      >
        Ver Más
      </button>
    </div>
    <transition name="fade">
      <div v-if="$store.state.openSearch">
        <searchComponent @nuevaBusqueda="nuevaBusqueda" />
      </div>
    </transition>
  </div>
</template>

<script>
import { formatoNumero } from "@/functions.js";
import togglebutton from "@/components/ToggleButton.vue";
import cxccard from "@/components/CXCCard.vue";
import getIdMixin from "../mixins/getIdMixin";
import searchComponent from "@/components/SearchComponent.vue";

export default {
  components: {
    togglebutton,
    cxccard,
    searchComponent,
  },
  mixins: [getIdMixin],
  data() {
    return {
      proveedorCXC: {
        totalOrdenesPagarUsd: 0,
        totalOrdenesPagarBs: 0,
        listaOrdenCompra: [],
      },
      continuaData: false,
      monedaUsd: true,
      componentKey: 0,
      selectCobrar: false,
      dataPaso: 10,
      filtroCobrar: "noPagado",
      filtroBusqueda: [
        {
          id: 0,
          fechaFin: null,
          fechaInicio: null,
          filtro: "noPagado",
          producto: null,
          dataInicio: 0,
          dataFin: 0,
        },
      ],
    };
  },
  async created() {
    this.$store.state.openSearch = false;
    this.filtroBusqueda[0].id = this.$store.state.user.id;
    this.filtroBusqueda[0].dataFin = this.dataPaso;
    await this.cuentaCobrarQuery();
  },
  mounted() {
    this.scroll();
  },
  methods: {
    formatoNumero,
    nuevaBusqueda(event) {
      this.filtroBusqueda[0].fechaInicio = event.fechaInicio;
      this.filtroBusqueda[0].fechaFin = event.fechaFin;
      this.filtroBusqueda[0].filtro = event.filtro;
      this.filtroBusqueda[0].dataInicio = 0;
      this.filtroBusqueda[0].dataFin = this.dataPaso;
      this.proveedorCXC.listaOrdenCompra = [];
      this.filtroCobrar = event.filtroCobrado;
      this.selectCobrar = event.selectCobrar;
      this.cuentaCobrarQuery();
    },
    cargarMasResultados() {
      this.filtroBusqueda[0].dataInicio += this.dataPaso;
      this.filtroBusqueda[0].dataFin += this.dataPaso;
      this.cuentaCobrarQuery();
    },
    setCheckboxVal(event) {
      this.monedaUsd = event.value;
      this.componentKey++;
    },
    async cuentaCobrarQuery() {
      this.$store.state.isLoading = true;
      if (this.$store.state.user.id == 0) {
        await this.getUserId();
        this.filtroBusqueda[0].id = this.$store.state.user.id;
      }
      await this.$apollo
        .query({
          query: require("@/graphql/queries/cuentasCobrarProveedor.gql"),
          variables: {
            id: this.$store.state.user.id,
            data: this.filtroBusqueda,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.proveedorCXC.totalOrdenesPagarUsd =
            data.data.cuentasCobrarProveedor[0].totalOrdenesPagarUsd;
          this.proveedorCXC.totalOrdenesPagarBs =
            data.data.cuentasCobrarProveedor[0].totalOrdenesPagarBs;

          this.proveedorCXC.totalOrdenesPagadoUsd =
            data.data.cuentasCobrarProveedor[0].totalOrdenesPagadoUsd;
          this.proveedorCXC.totalOrdenesPagadoBs =
            data.data.cuentasCobrarProveedor[0].totalOrdenesPagadoBs;
          data.data.cuentasCobrarProveedor[0].listaOrdenCompra.map((x) => {
            this.proveedorCXC.listaOrdenCompra.push(x);
          });
          this.continuaData = data.data.cuentasCobrarProveedor[0].continuaData;
          this.componentKey++;
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight * 0.98;

        if (
          bottomOfWindow &&
          !this.$store.state.isLoading &&
          this.continuaData
        ) {
          this.cargarMasResultados();
        }
      };
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
