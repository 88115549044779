<template>
  <!-- This is an example component -->
  <div>
    <div
      class="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center"
      style="background: rgba(0, 0, 0, 0.3)"
    >
      <div
        class="bg-white border py-2 px-5 rounded-lg flex items-center flex-col w-4/5"
      >
        <div class="flex justify-end w-full mt-3">
          <button class="w-5 h-5" @click="$store.state.openSearch = false">
            <img src="@/assets/iconos/closeIcon.svg" alt="cerrar" />
          </button>
        </div>
        <div class="text-xl mt-2 text-center">
          <div class="mt-1">
            <h4 class="font-medium" for="">Fecha Inicio:</h4>
            <input
              class="bg-white border-2 border-green-300 rounded-md w-40 text-center pl-5"
              type="date"
              name=""
              id=""
              v-model="dataBusquerda.fechaInicio"
            />
          </div>
          <div class="my-6">
            <h4 class="font-medium" for="">Fecha Fin:</h4>
            <input
              class="bg-white border-2 border-green-300 rounded-md w-40 text-center pl-5"
              type="date"
              name=""
              id=""
              v-model="dataBusquerda.fechaFin"
            />
          </div>
          <div class="mb-8">
            <h4 class="font-medium" for="">Filtrar por:</h4>
            <select
              class="bg-white border-2 border-green-300 rounded-md w-40 text-center"
              name=""
              id=""
              v-model="dataBusquerda.filtroBusqueda"
            >
              <option value="noPagado" selected>No Cobrado</option>
              <option value="pagado">Cobrado</option>
              <option value="todos">Todos</option>
            </select>
          </div>
          <div class="flex justify-center w-full mb-6">
            <button
              class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
              @click="nuevaBusqueda"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ 
export default {
  data() {
    return {
      dataBusquerda: {
        fechaInicio: null,
        fechaFin: null,
        filtroBusqueda: "noPagado",
      },
    };
  },
  methods: {
    nuevaBusqueda() {
      let fechaInicio = new Date(this.dataBusquerda.fechaInicio);
      let fechaFin = new Date(this.dataBusquerda.fechaFin);
      let emitirEvento = false;

      if (fechaInicio > fechaFin) {
        alert("La fecha de inicio tiene que ser antes de la fecha de fin.");
      } else {
        if (
          this.dataBusquerda.fechaInicio === null ||
          this.dataBusquerda.fechaFin === null
        ) {
          alert("Por favor elegir fecha de inicio y fin.");
        } else {
          emitirEvento = true;
        }
      }
      if (emitirEvento) {
        let filtroSelectActive = false;
        let filtroSelect = this.dataBusquerda.filtroBusqueda;

        if (this.dataBusquerda.filtroBusqueda === "todos") {
          filtroSelectActive = true;
          filtroSelect = "noPagado";
        }

        this.$emit("nuevaBusqueda", {
          fechaInicio: this.dataBusquerda.fechaInicio,
          fechaFin: this.dataBusquerda.fechaFin,
          filtro: this.dataBusquerda.filtroBusqueda,
          filtroCobrado: filtroSelect,
          selectCobrar: filtroSelectActive,
        });
        this.$store.state.openSearch = false;
      }
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
